/* .p-multiselect .p-multiselect-label.p-placeholder {
    color: #233145;
    font-weight: 500;
    font-size: 14px;
}
.p-dropdown .p-dropdown-label.p-placeholder {
    color: #233145;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {

    font-size: 14px;
    font-weight: 500;
    color: rgb(35, 49, 69);
}

.p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    color: #233145;
    font-size: Roboto;
    font-size: 14px;
    font-weight: 500;
} */



.p-multiselect .p-multiselect-label.p-placeholder,
.p-multiselect .p-multiselect-label,
.p-multiselect .p-multiselect-token-label,
.p-multiselect-disabled .p-multiselect-token-label {
    color: rgb(35, 49, 69) !important;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Roboto';
}

.p-multiselect:hover{
    border: 1px solid transparent;
}


.p-dropdown .p-dropdown-label.p-placeholder,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item, 
.p-dropdown .p-dropdown-label,
.p-dropdown-disabled .p-dropdown-label {
    font-family: 'Roboto';
    color: rgb(35, 49, 69) !important;
    font-weight: 500;
    font-size: 14px;
}



.pi-chevron-down:before {
    content: none !important; 
}

.pi-chevron-right:before {
    content: none !important; 
}

input, select, optgroup, textarea {
    background-color:#F8F9FA;
    box-shadow: 0px 4px 4px 0px #0000000A inset;
}


input[type="password"]::-ms-reveal {
    display: none;
}

input[type="password"]::-webkit-textfield-decoration-container {
    display: none;
}